import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const BalletPage = () => (
  <Layout descriptionText={"Ballet"}>
    <Seo title="ballet" />
    <Gallery categoryName="ballet" />
  </Layout>
);

export default BalletPage;
